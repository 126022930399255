/**
 * お知らせ一覧のクエリパラメータコントロール用middleware
 */
export default defineNuxtRouteMiddleware((to, from) => {
  if (!to.query.page) {
    return navigateTo({
      path: '/notice',
      query: {
        page: 1,
      },
    });
  }
});
